import 'jquery/dist/jquery.min'
import 'jquery-ui'
import 'bootstrap-sass/assets/javascripts/bootstrap.min'

import 'datatables'
import 'datatables.net-buttons-bs';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';

import './js/bootstrap-datepicker/bootstrap-datepicker.min'
import './js/chosen/chosen.jquery.min'
import './js/core'

import './styles/css/chosen/chosen.min.css'
import './styles/css/chosen/chosen-bootstrap.min.css'
import './styles/css/bootstrap-datepicker/bootstrap-datepicker3.min.css'
import './styles/css/datatables/dataTables.bootstrap.css'

// create global $ and jQuery variables
const $ = require('jquery');
global.$ = global.jQuery = $;
