/*
 * Copyright (C) Edouard Baudry - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Edouard Baudry <edouard@laponi.fr>, September 2015
 */

var chosen_browser_is_supported = function () {
    if ("Microsoft Internet Explorer" === window.navigator.appName) {
        return document.documentMode >= 8;
    }
    return !(/iP(od|hone)/i.test(window.navigator.userAgent) || /IEMobile/i.test(window.navigator.userAgent) || /Windows Phone/i.test(window.navigator.userAgent) || /BlackBerry/i.test(window.navigator.userAgent) || /BB10/i.test(window.navigator.userAgent) || /Android.*Mobile/i.test(window.navigator.userAgent));
};

$(function () {
    /**
     * Apply CHOSEN
     */
    refreshChosen();

    /**
     * Apply DATEPICKER
     */
    refreshDatepickers();


    /**
     * Apply BOOTSTRAP
     */
    // DROPDOWN
    //------------------------------------
    if ($.isFunction($.fn.dropdown)) {
        $('.dropdown-toggle').dropdown();
    }

    // TOOLTIPS
    //------------------------------------
    if ($.isFunction($.fn.tooltip)) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    // ALERTS
    //------------------------------------
    if ($.isFunction($.fn.alert)) {
        $('.alert-js').alert();
    }

    // Refresh select chosen when modals form with multiple select is open. (You must add the class "modal-chosen" to your modal div and add the class "chosen-lazy" on the select element inside the modal)
    $('.modal-chosen').on('shown.bs.modal', function () {
        refreshChosen($(this));
    });


    // MODAL
    //------------------------------------
    /**
     * add confirmation to link which have data-confirm
     * /!\ WARNING manage the case where there are multiple submit buttons and you want to see which one was clicked on the server side
     *
     * @thanks cf. http://feub.net/2014/05/utiliser-bootstrap-pour-remplacer-la-fenetre-de-confirmation-javascript/
     */
    $('button[data-confirm]').click(function (ev) {
        var $form = $(this).closest('form')[0];
        if ($form.checkValidity() === false) {                                  // If the form is not valid, don't show the popup
            return true;
        }
        ev.preventDefault();

        //noinspection JSJQueryEfficiency : Keep everywhere the selector
        if (!$('#dataConfirmModal').length) {                                   // If the modal HTML is not present, create it.
            $('body').append('<div id="dataConfirmModal" class="modal" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button><h3 id="dataConfirmLabel">Merci de confirmer</h3></div><div class="modal-body"></div><div class="modal-footer"><button class="btn" data-dismiss="modal" aria-hidden="true">Non</button><a class="btn btn-danger" id="dataConfirmOK">Oui</a></div></div></div></div>');
        }
        var title = $(this).attr('data-confirm-title');     // Replace title if set
        if (title) {
            $('#dataConfirmModal').find('#dataConfirmLabel').text(title);
        }
        var content = $(this).attr('data-confirm');         // Replace content if set
        if (content) {
            $('#dataConfirmModal').find('.modal-body').html('<div>' + content + '</div>');
        }

        $('#dataConfirmModal').modal({backdrop: 'static', keyboard: false})     // Prepare the modal
            .one('click', '#dataConfirmOK', function () {
                $form.submit();
            }) // .one() is NOT a typo of .on()
            .one('click', '#dataConfirmKO', function () {
                $action.val('');
            }); // .one() is NOT a typo of .on()
        $('#dataConfirmModal').modal({show: true});                             // Show the modal

        return false;
    });
});

/**
 * Apply DATEPICKER
 */
function refreshDatepickers() {
    if ($.isFunction($.fn.datepicker)) {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayHighlight: true,
            autoclose: true,
            disableTouchKeyboard: true
        });
    }
}

/**
 * Apply CHOSEN
 */
function refreshChosen(baseSelector) {
    if ($.isFunction($.fn.chosen)) {
        /** copy AbstractChosen.browser_is_supported from Chosen because
         *
         * @returns {boolean}
         */
        let selectElements = baseSelector
            ? $(baseSelector).find('select:not(".no-chosen")')
            : $('select:not(".no-chosen,.chosen-lazy")');
        selectElements.each(function () {
            $(this).attr('data-placeholder', $(this).attr('placeholder'));
            $(this).chosen({
                allow_single_deselect: true,
                search_contains: true
            });/*.on('chosen:no_results', function(event, params) {
             var oldValue = this.search_value;
             var newValue = $(event.target).val();
             console.log(newValue);
             $(this).find("option[value='" + oldValue + "']").remove();
             $(this).append(new Option(this.search_value, this.search_value, true, true));
             $(this).trigger('chosen:updated');
             this.search_value = newValue;
             });*/
            if (chosen_browser_is_supported()) {
                this.setAttribute('style', 'display:visible; position:absolute; clip:rect(0,0,0,0)');
            }    // By default, chosen does not manage "required" attribute for HTML5 validation. This is a workaround
        });
    }
}

/**
 * @see https://github.com/janl/mustache.js/blob/master/mustache.js#L71
 * @param value
 * @returns {string}
 */
function escapeHtml(value) {
    if (!value) {
        return ""
    }

    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    return String(value).replace(/[&<>"'`=\/]/g, function fromEntityMap(s) {
        return entityMap[s];
    });
}

export {escapeHtml, chosen_browser_is_supported, refreshChosen, refreshDatepickers}
